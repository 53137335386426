import request from '@/utils/request'

export function getMemberOrder(parameter) {
  return request({
    url: '/member/order',
    method: 'get',
    params: parameter
  })
}

export function queryMemberOrders(parameter) {
  // debugger;
  return request({
    url: '/member/order',
    method: 'post',
    data: parameter
  })
}

export function queryMemberStatistics(parameter) {
  // debugger;
  return request({
    url: '/member/order/finance/order',
    method: 'post',
    data: parameter
  })
}

export function putMemberOrder(parameter) {
  return request({
    url: '/member/order',
    method: 'put',
    data: parameter
  })
}

export function addMemberOrder(parameter) {
  return request({
    url: '/member/order/add',
    method: 'put',
    data: parameter
  })
}

export function addPackageOrder(parameter) {
  // debugger;
  return request({
    url: '/member/order/add/package',
    method: 'put',
    data: parameter
  })
}

export function cancelMemberOrder(parameter) {
  return request({
    url: '/member/order/cancel',
    method: 'post',
    data: parameter
  })
}

export function deliverMemberOrder(parameter) {
  return request({
    url: '/member/order/deliver',
    method: 'post',
    data: parameter
  })
}

export function getMemberOrderAddress(parameter) {
  return request({
    url: '/member/order/address',
    method: 'get',
    params: parameter
  })
}

export function putMemberOrderAddress(parameter) {
  return request({
    url: '/member/order/address',
    method: 'put',
    data: parameter
  })
}

export function getMemberOrderProductions(parameter) {
  return request({
    url: '/member/order/production',
    method: 'post',
    data: parameter
  })
}

export function getOrderData(parameter) {
  return request({
    url: '/member/order/data',
    method: 'get',
    params: parameter
  })
}

export function payMemberOrder(parameter) {
  return request({
    url: '/member/order/pay',
    method: 'POST',
    data: parameter
  })
}

export function getMemberBank(parameter) {
  return request({
    url: '/member/order/bank',
    method: 'get',
    params: parameter
  })
}

export function getMemberCompute(parameter) {
  return request({
    url: '/member/order/compute',
    method: 'get',
    params: parameter
  })
}

export function deleteMemberOrder(parameter) {
  return request({
    url: '/member/order',
    method: 'delete',
    params: parameter
  })
}

export function getOrderCorrect(parameter) {
  return request({
    url: '/member/order/correct/detail/' + parameter.id,
    method: 'get',
  })
}

export function setOrderCorrect(parameter) {
  return request({
    url: '/member/order/correct',
    method: 'post',
    data: parameter
  })
}

export function queryExchangeRecordDetail(parameter) {
  return request({
    url: '/integral/member/exchange/record',
    method: 'GET',
    params: parameter
  })
}

export function queryNumShareRecordDetail(parameter) {
  return request({
    url: '/integral/member/share/count/record',
    method: 'GET',
    params: parameter
  })
}

export function queryCumulateRecordDetail(parameter) {
  return request({
    url: '/integral/member/cumu/value/record',
    method: 'GET',
    params: parameter
  })
}
//499名额明细
export function queryActiveDetail(parameter) {
  return request({
    url: '/wealth/quota/record',
    method: 'GET',
    params: parameter
  })
}
//权益额度流水
export function queryPartnerRightDetail(parameter) {
  return request({
    url: '/integral/member/member/rights/record',
    method: 'GET',
    params: parameter
  })
}
export function getMerchantOrderData(parameter) {
  return request({
    url: '/member/order/data/v2',
    method: 'get',
    params: parameter
  })
}

export function queryMerchantWaitOrder(parameter) {
  return request({
    url: '/member/order/merchant/wait/page',
    method: 'post',
    data: parameter
  })
}

export function updateMemberOrder(parameter) {
  return request({
    url: '/member/order/update/alreadypay',
    method: 'post',
    data: parameter
  })
}

export function updateMerchantQuantity(parameter) {
  return request({
    url: '/wealth/quota/update/v2',
    method: 'post',
    data: parameter
  })
}

export function queryMerchantCash(parameter) {
  return request({
    url: '/merchant/cash/page',
    method: 'GET',
    params: parameter
  })
}

export function getAuditOrderData(parameter) {
  return request({
    url: '/member/order/audit/statistics',
    method: 'POST',
    data: parameter
  })
}

export function removeAuditOrder(parameter) {
  return request({
    url: '/member/order/audit/del/' + parameter.id,
    method: 'delete',
  })
}

export function putAuditOrder(parameter) {
  return request({
    url: '/member/order/audit/save',
    method: 'POST',
    data: parameter
  })
}

export function putOrderExtra(parameter) {
  return request({
    url: '/member/order/audit/extra/save',
    method: 'POST',
    data: parameter
  })
}

export function getAuditRecord(parameter) {
  return request({
    url: '/member/order/audit/record/' + parameter.id,
    method: 'GET',
  })
}

export function getMobileMember(parameter) {
  return request({
    url: '/member/phone',
    method: 'GET',
    params: parameter
  })
}

export function getMerchantList(parameter) {
  return request({
    url: '/member/merchant',
    method: 'GET',
    params: parameter
  })
}

export function querySpecialOrders(parameter) {
  return request({
    url: '/member/order/special',
    method: 'post',
    data: parameter
  })
}

export function getSpecialTotal(parameter) {
  return request({
    url: '/member/order/special/total',
    method: 'post',
    data: parameter
  })
}

export function getQuotaOrderDetail(parameter) {
  return request({
    url: '/wealth/quota/order/detail',
    method: 'GET',
    params: parameter
  })
}

export function getMobileMerchant(parameter) {
  return request({
    url: '/member/bind/query',
    method: 'GET',
    params: parameter
  })
}

export function putBindMerchant(parameter) {
  return request({
    url: '/member/bind/merchant',
    method: 'post',
    data: parameter
  })
}

export function putOrderProduction(parameter) {
  return request({
    url: '/member/order/update/production',
    method: 'post',
    data: parameter
  })
}

export function getDepositoryStatistics(parameter) {
  return request({
    url: '/member/order/depository/statistics',
    method: 'post',
    data: parameter
  })
}


export function putOrderDraw(parameter) {
  return request({
    url: '/member/order/draw',
    method: 'put',
    data: parameter
  })
}

export function queryDiscount(parameter) {
  return request({
    url: '/wealth/quota/month/record',
    method: 'get',
    params: parameter
  })
}

export function queryDiscountHeard(parameter) {
  return request({
    url: '/wealth/quota/month/record/data',
    method: 'get',
    params: parameter
  })
}

export function queryCash(parameter) {
  return request({
    url: '/member/record/cash',
    method: 'get',
    params: parameter
  })
}

export function queryCashHead(parameter) {
  return request({
    url: '/member/record/cash/data',
    method: 'get',
    params: parameter
  })
}

export function queryActivityOrder(parameter) {
  return request({
    url: '/sign/tool/activity/order/page/v2',
    method: 'post',
    data: parameter
  })
}

export function deleteActivityOrder(parameter) {
  return request({
    url: '/sign/tool/activity/order/del/' + parameter.id,
    method: 'delete',
  })
}

export function outActivityOrder(parameter) {
  return request({
    url: '/sign/tool/activity/order/out/' + parameter.id,
    method: 'post',
  })
}

export function putMemberOrderTransfer(parameter) {
  return request({
    url: '/member/order/transfer',
    method: 'post',
    data: parameter
  })
}

export function getOrderLedgerStatistics(parameter) {
  return request({
    url: '/member/order/ledger/statistics',
    method: 'post',
    data: parameter
  })
}

export function getOrderFinanceStatistics(parameter) {
  return request({
    url: '/member/order/finance/order/statistics',
    method: 'post',
    data: parameter
  })
}

export function getOrderLedgerAccount(parameter) {
  return request({
    url: '/member/order/ledger/balance/list',
    method: 'get',
    params: parameter
  })
}

export function putOrderLedger(parameter) {
  return request({
    url: '/member/order/ledger/add',
    method: 'post',
    data: parameter
  })
}



