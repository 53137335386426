<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card title="基础信息" :bordered='false' style="margin-bottom: 20px;">
      <a-descriptions style="margin-bottom: 30px" :column="4">
        <!--        <a-descriptions-item label='昵称'>{{ dashboard.nickname }}</a-descriptions-item>-->

        <a-descriptions-item label='手机号'>{{ dashboard.mobile }}</a-descriptions-item>
        <a-descriptions-item label='注册时间' :span="3">{{dashboard.createTime}}</a-descriptions-item>

        <a-descriptions-item label='姓名'>{{dashboard.username}}</a-descriptions-item>
        <a-descriptions-item label='性别'>{{dashboard.gender=='male'?'男':'女'}}</a-descriptions-item>
        <a-descriptions-item label='身份证号码' :span="2">{{dashboard.identifier}}</a-descriptions-item>


        <a-descriptions-item label='出生日期'>{{dashboard.birthday}}</a-descriptions-item>
        <a-descriptions-item label='现住地址'>{{dashboard.address}} </a-descriptions-item>
        <a-descriptions-item label='身份' :span="2">{{getTypeNameStr(dashboard.type,dashboard)}}
          <template v-if="dashboard.type == 'normal'">
            <a-divider type='vertical' /> <a @click='handelUserLevel()'>修改</a>
          </template>
        </a-descriptions-item>

        <a-descriptions-item label='会员名额'>
          {{dashboard.partnerQuota}}
          <a-divider type='vertical' /> <a @click='handelMemberInitial()'>修改</a>
          <a-divider type='vertical' /> <a @click='handleInitialTotal(deliver13)'>查看明细</a>
        </a-descriptions-item>

        <a-descriptions-item label='合伙人名额' >
            {{dashboard.presidentQuota}}
          <a-divider type='vertical' /> <a @click='handleInitial15()'>修改</a>
          <a-divider type='vertical' /> <a @click='handleInitialTotal(deliver16)'>查看明细</a>
        </a-descriptions-item>
        <a-descriptions-item label='权益有效期'>
          {{ dashboard.partner_end }}
        </a-descriptions-item>
        <a-descriptions-item label='剩余额度'>
          {{dashboard.partnerBalance}}
          <a-divider type='vertical' /> <a @click='handleInitial14()'>修改</a>
          <a-divider type='vertical' /> <a @click='handleInitialTotal(deliver17)'>查看明细</a>
        </a-descriptions-item>

        <a-descriptions-item label='身份证正面'>
          <img v-if='dashboard.idFront' :src='dashboard.idFront' width='100px' height="100px" preview="1">
        </a-descriptions-item>
        <a-descriptions-item label='身份证反面' :span="3">
          <img v-if='dashboard.idBack' :src='dashboard.idBack' width='100px' height="100px" preview="1">
        </a-descriptions-item>


      </a-descriptions>
    </a-card>

    <a-card title="关系信息" :bordered='false' style="margin-bottom: 20px;">

      <a-descriptions style="margin-bottom: 30px" :column="3">
        <a-descriptions-item label='推荐人姓名'>{{dashboard.spreaderName}}</a-descriptions-item>
        <a-descriptions-item label='推荐手机号'>{{dashboard.spreaderMobile}}</a-descriptions-item>
        <a-descriptions-item label='推荐人身份'>{{getSpreaderTypeNameStr(dashboard.spreaderType,dashboard)}}</a-descriptions-item>

        <a-descriptions-item label='分公司名称'>{{dashboard.areaName}}</a-descriptions-item>
        <!--        <a-descriptions-item label='品牌公司'>{{dashboard.servicePresidentShortName}}</a-descriptions-item>-->
      </a-descriptions>

    </a-card>

    <a-card title="积分信息" :bordered='false' style="margin-bottom: 20px;">

      <a-descriptions style="margin-bottom: 30px" :column="3">
        <a-descriptions-item label='可用积分合计'>{{dashboard.alreadyUsedCash+dashboard.cashBalance}}  </a-descriptions-item>
        <a-descriptions-item label='兑换积分合计'>{{dashboard.alreadyExchangeIntegral+dashboard.exchangeIntegralBalance}}</a-descriptions-item>
        <a-descriptions-item label=''></a-descriptions-item>

        <a-descriptions-item label='可用积分已用'>{{dashboard.alreadyUsedCash}}</a-descriptions-item>
        <a-descriptions-item label='兑换积分已用'>{{dashboard.alreadyExchangeIntegral}}</a-descriptions-item>
        <a-descriptions-item label=''></a-descriptions-item>

        <a-descriptions-item label='可用积分剩余'>{{dashboard.cashBalance}}&nbsp;
          <a-divider type='vertical' /><a @click='handelInitialCashBalance()'>修改</a>
          <a-divider type='vertical' /><a @click='handleInitial1()'>查看明细</a>
        </a-descriptions-item>
        <a-descriptions-item label='兑换积分剩余'>{{dashboard.exchangeIntegralBalance}}
          <a-divider type='vertical' /><a @click='handelInitialDuihuan()'>修改</a>
          <a-divider type='vertical' /><a @click='handleInitial2()'>查看明细</a></a-descriptions-item>
        <a-descriptions-item label=''></a-descriptions-item>
      </a-descriptions>

    </a-card>

    <a-card title="份额信息" :bordered='false'>

      <a-descriptions style="margin-bottom: 30px" :column="3">
        <a-descriptions-item label='原始份额数'>{{dashboard.finishedShareCountTotal}}
          <a-divider type='vertical' /><a @click='handelInitialShareValue()'>修改</a>
          <a-divider type='vertical' /><a @click='handleInitial6()'>查看明细</a>
        </a-descriptions-item>
        <a-descriptions-item label='原始累计值'>{{dashboard.cumulativeValue}}
          <a-divider type='vertical' /> <a @click='handelInitialCumulativeValueBalance()'>修改</a>
          <a-divider type='vertical' /><a @click='handleInitial7()'>查看明细</a>
        </a-descriptions-item>
        <a-descriptions-item label=''></a-descriptions-item>

        <a-descriptions-item label='扣除份额数'>{{ Math.round((dashboard.finishedShareCountTotal - dashboard.finishedShareCountBalance)*100)/100 }} </a-descriptions-item>
        <a-descriptions-item label='已用累计值'>{{dashboard.cumulativeValue-dashboard.cumulativeValueBalance}}</a-descriptions-item>
        <a-descriptions-item label=''></a-descriptions-item>


        <a-descriptions-item label='剩余份额数'>{{dashboard.finishedShareCountBalance}}</a-descriptions-item>
        <!--        <a-descriptions-item label='进行中份额数'>{{dashboard.shareValue}}-->
        <!--          <a-divider type='vertical' /><a @click='handelInitialShareValue(dashboard)'>修改</a>-->
        <!--        </a-descriptions-item>-->
        <a-descriptions-item label='剩余累计值'>{{dashboard.cumulativeValueBalance}} </a-descriptions-item>
        <a-descriptions-item label=''></a-descriptions-item>
      </a-descriptions>

    </a-card>

    <a-modal title='可用积分明细' :destroyOnClose='true' :maskClosable='true' width="70%"
             @cancel='()=>this.deliver1.visible=false' :footer="null"
             :visible='deliver1.visible' :confirmLoading='deliver1.loading'>
      <a-descriptions  :column="5">
        <a-descriptions-item label='用户名字' ><span class="color-red">{{ dashboard.nickname }}</span></a-descriptions-item>
        <!--        <a-descriptions-item label='积分' class="color-red"><span class="color-red">{{dashboard.alreadyUsedCash+dashboard.cashBalance}}</span></a-descriptions-item>-->
        <a-descriptions-item label='积分' class="color-red"><span class="color-red">{{this.ext4}}</span></a-descriptions-item>
        <a-descriptions-item label='剩余' class="color-red"><span class="color-red">{{dashboard.cashBalance}}</span></a-descriptions-item>
      </a-descriptions>
      <a-form-model ref='form1' :model='deliver1.form'  layout='inline'>
        <a-form-model-item prop='type'>
          <a-select v-model="deliver1.form.type" placeholder="变动类型" style="width: 250px">
            <a-select-option :value="undefined">
              变动类型
            </a-select-option>
            <a-select-option :value="15">
              推广
            </a-select-option>
            <!--            <a-select-option :value="16">-->
            <!--              推广+活动-->
            <!--            </a-select-option>-->
            <a-select-option :value="28">
              业绩15%
            </a-select-option>
            <a-select-option :value="26">
              业绩5%
            </a-select-option>

            <a-select-option :value="17">
              业绩2.5%
            </a-select-option>
            <a-select-option :value="24">
              业绩2%
            </a-select-option>
            <a-select-option :value="25">
              业绩1-1%
            </a-select-option>
            <a-select-option :value="27">
              业绩1%
            </a-select-option>
            <a-select-option :value="19">
              消费积分
            </a-select-option>
            <a-select-option :value="21">
              批量兑换
            </a-select-option>
            <a-select-option :value="22">
              批量兑换退回
            </a-select-option>
            <a-select-option :value="7">
              取删退回
            </a-select-option>
            <a-select-option :value="1">
              使用
            </a-select-option>
            <a-select-option :value="5">
              转赠
            </a-select-option>
            <a-select-option :value="6">
              受赠
            </a-select-option>
            <a-select-option :value="2">
              修改
            </a-select-option>
            <a-select-option :value="9">
              门店补贴
            </a-select-option>
            <a-select-option :value="29">
              拓展服务
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop='start'>
          <a-range-picker
              v-model="dateArr"
              :format="[formatType, formatType]"
              :show-time="{
            hideDisabledOptions: true
          }"
              :disabled-date="disabledDate"
              @change="dateChange"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type='primary' @click='handleSubmit1'>
            查询
          </a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click='handleReset1'>
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <s-table id="table1" ref='table1' rowKey='id' size='default' :columns='columns1' :pageSize='30' :scroll='{ x: 1500}' :data='loadData1'>
        <span slot='orderId' slot-scope='text, record'>
          <template>
              {{showCaseText(record)}}
          </template>
        </span>

        <span slot='des' slot-scope='text, record'>
          <template>
              备注：{{text}}
          </template>
        </span>
      </s-table>
    </a-modal>

    <a-modal title='兑换积分明细' :destroyOnClose='true' :maskClosable='true' width="70%"
             @cancel='()=>this.deliver2.visible=false' :footer="null"
             :visible='deliver2.visible' :confirmLoading='deliver2.loading'>
      <a-descriptions  :column="5">
        <a-descriptions-item label='用户名字' ><span class="color-red">{{ dashboard.nickname }}</span></a-descriptions-item>
        <a-descriptions-item label='积分' class="color-red"><span class="color-red">{{dashboard.alreadyExchangeIntegral+dashboard.exchangeIntegralBalance}}</span></a-descriptions-item>
        <a-descriptions-item label='剩余' class="color-red"><span class="color-red">{{dashboard.exchangeIntegralBalance}}</span></a-descriptions-item>
      </a-descriptions>
      <a-form-model ref='form2' :model='deliver2.form'  layout='inline'>
        <a-form-model-item prop='type'>
          <a-select v-model="deliver2.form.type" placeholder="变动类型" style="width: 250px">
            <a-select-option :value="undefined">
              变动类型
            </a-select-option>
            <a-select-option :value="1">
              使用
            </a-select-option>
            <a-select-option :value="14">
              获得
            </a-select-option>
            <a-select-option :value="23">
              解约
            </a-select-option>
            <a-select-option :value="201">
              修改增加
            </a-select-option>
            <a-select-option :value="202">
              修改减少
            </a-select-option>

          </a-select>
        </a-form-model-item>

        <a-form-model-item prop='start'>
          <a-range-picker
              v-model="dateArr2"
              :format="[formatType, formatType]"
              :show-time="{
            hideDisabledOptions: true
          }"
              :disabled-date="disabledDate2"
              @change="dateChange2"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type='primary' @click='handleSubmit2'>
            查询
          </a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click='handleReset2'>
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <s-table id="table2" ref='table2' rowKey='id' size='default' :columns='columns2' :pageSize='30' :scroll='{ x: 1500}' :data='loadData2'>
        <!--            <span slot='des' slot-scope='text, record'>-->
        <!--              <template>-->
        <!--                  备注：{{text}}-->
        <!--              </template>-->
        <!--            </span>-->
      </s-table>
    </a-modal>

    <a-modal title='累计值修改' :destroyOnClose='true' :maskClosable='true' width="30%"
             @cancel='()=>this.deliver3.visible=false' @ok="updateCumulativeValueBalance"
             :visible='deliver3.visible' :confirmLoading='deliver3.loading'>
      <a-form-model ref='form3' :model='deliver3.form' :rules='deliver3.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>

        <a-form-model-item  label="修改" prop="status">
          <a-select v-model="deliver3.form.status"  @change="updateValue3(deliver3.form)">
            <a-select-option :value="1">
              增加
            </a-select-option>
            <a-select-option :value="2">
              减少
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label='修改值' prop='value'>
          <a-input v-model='deliver3.form.value'  @change="updateValue3(deliver3.form)"/>
        </a-form-model-item>
        <a-form-model-item label='备注内容' prop='remark'>
          <a-input v-model='deliver3.form.remark' />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='份额数修改' :destroyOnClose='true' :maskClosable='true' width="30%"
             @cancel='()=>this.deliver4.visible=false' @ok="updateShareValue"
             :visible='deliver4.visible' :confirmLoading='deliver4.loading'>
      <a-form-model ref='form4' :model='deliver4.form' :rules='deliver4.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>

        <a-form-model-item  label="修改" prop="status">
          <a-select v-model="deliver4.form.status"  @change="updateValue4(deliver4.form)">
            <a-select-option :value="1">
              增加
            </a-select-option>
            <a-select-option :value="2">
              减少
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label='修改值' prop='value'>
          <a-input v-model='deliver4.form.value' @blur="updateValue4(deliver4.form)"/>
        </a-form-model-item>
        <a-form-model-item label='备注内容' prop='remark'>
          <a-input v-model='deliver4.form.remark' />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='份额值修改' :destroyOnClose='true' :maskClosable='true' width="30%"
             @cancel='()=>this.deliver5.visible=false' @ok="updateShare"
             :visible='deliver5.visible' :confirmLoading='deliver5.loading'>
      <a-form-model ref='form5' :model='deliver5.form' :rules='deliver5.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>

        <a-form-model-item  label="修改" prop="status">
          <a-select v-model="deliver5.form.status"  @change="updateValue4(deliver5.form)">
            <a-select-option :value="1">
              增加
            </a-select-option>
            <a-select-option :value="2">
              减少
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label='修改值' prop='value'>
          <a-input v-model='deliver5.form.value' @blur="updateValue4(deliver5.form)"/>
        </a-form-model-item>
        <a-form-model-item label='备注内容' prop='remark'>
          <a-input v-model='deliver5.form.remark' />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='份额数明细' :destroyOnClose='true' :maskClosable='true' width="70%"
             @cancel='()=>this.deliver6.visible=false' :footer="null"
             :visible='deliver6.visible' :confirmLoading='deliver6.loading'>
      <a-form-model ref='form6' :model='deliver6.form'  layout='inline'>
        <a-form-model-item prop='type'>
          <a-select v-model="deliver6.form.type" placeholder="变动类型" style="width: 250px">
            <a-select-option :value="undefined">
              变动类型
            </a-select-option>
            <a-select-option :value="3">
              订单扣除
            </a-select-option>
            <a-select-option :value="1">
              订单获得
            </a-select-option>
            <a-select-option :value="201">
              修改增加
            </a-select-option>
            <a-select-option :value="202">
              修改减少
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop='start'>
          <a-range-picker
              v-model="dateArr6"
              :format="[formatType, formatType]"
              :show-time="{
            hideDisabledOptions: true
          }"
              :disabled-date="disabledDate2"
              @change="dateChange6"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type='primary' @click='handleSubmit6'>
            查询
          </a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click='handleReset6'>
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <s-table id="table6" ref='table6' rowKey='id' size='default' :columns='columns6' :pageSize='30' :scroll='{ x: 1500}' :data='loadData6'>
      </s-table>
    </a-modal>

    <a-modal title='累计值明细' :destroyOnClose='true' :maskClosable='true' width="70%"
             @cancel='()=>this.deliver7.visible=false' :footer="null"
             :visible='deliver7.visible' :confirmLoading='deliver7.loading'>
      <a-form-model ref='form7' :model='deliver7.form'  layout='inline'>
        <a-form-model-item prop='type'>
          <a-select v-model="deliver7.form.type" placeholder="变动类型" style="width: 250px">
            <a-select-option :value="undefined">
              变动类型
            </a-select-option>
            <a-select-option :value="4">
              使用
            </a-select-option>
            <a-select-option :value="1">
              获得
            </a-select-option>
            <a-select-option :value="301">
              修改增加
            </a-select-option>
            <a-select-option :value="302">
              修改减少
            </a-select-option>


          </a-select>
        </a-form-model-item>

        <a-form-model-item prop='start'>
          <a-range-picker
              v-model="dateArr7"
              :format="[formatType, formatType]"
              :show-time="{
            hideDisabledOptions: true
          }"
              :disabled-date="disabledDate2"
              @change="dateChange7"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type='primary' @click='handleSubmit7'>
            查询
          </a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click='handleReset7'>
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <s-table id="table7" ref='table7' rowKey='id' size='default' :columns='columns7' :pageSize='30' :scroll='{ x: 1500}' :data='loadData7'>
            <span slot='type' slot-scope='text'>
            <template>
              {{ showTypeText7(text) }}
            </template>
          </span>
      </s-table>
    </a-modal>

    <a-modal title='可用积分修改' :destroyOnClose='true' :maskClosable='true' width="30%"
             @cancel='()=>this.deliver8.visible=false' @ok="updateCashBalance"
             :visible='deliver8.visible' :confirmLoading='deliver8.loading'>
      <a-form-model ref='form8' :model='deliver8.form' :rules='deliver5.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>

        <a-form-model-item  label="修改" prop="status">
          <a-select v-model="deliver8.form.status"  @change="updateValue8(deliver8.form)">
            <a-select-option :value="1">
              增加
            </a-select-option>
            <a-select-option :value="2">
              减少
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label='修改值' prop='value'>
          <a-input v-model='deliver8.form.value' @blur="updateValue8(deliver8.form)"/>
        </a-form-model-item>
        <a-form-model-item label='备注内容' prop='remark'>
          <a-input v-model='deliver8.form.remark' />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='兑换积分修改' :destroyOnClose='true' :maskClosable='true' width="30%"
             @cancel='()=>this.deliver9.visible=false' @ok="updateExchange"
             :visible='deliver9.visible' :confirmLoading='deliver9.loading'>
      <a-form-model ref='form9' :model='deliver9.form' :rules='deliver9.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>

        <a-form-model-item  label="修改" prop="status">
          <a-select v-model="deliver9.form.status"  @change="updateValue8(deliver9.form)">
            <a-select-option :value="1">
              增加
            </a-select-option>
            <a-select-option :value="2">
              减少
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label='修改值' prop='value'>
          <a-input v-model='deliver9.form.value' @blur="updateValue8(deliver9.form)"/>
        </a-form-model-item>
        <a-form-model-item label='备注内容' prop='remark'>
          <a-input v-model='deliver9.form.remark' />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='分值类型修改' :destroyOnClose='true' :maskClosable='true' width="30%"
             @cancel='()=>this.deliver10.visible=false' @ok="updateFillStatus"
             :visible='deliver10.visible' :confirmLoading='deliver10.loading'>
      <a-form-model ref='form10' :model='deliver10.form' :rules='deliver10.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>

        <a-form-model-item  label="分值类型" prop="fillStatus">
          <a-select v-model="deliver10.form.fillStatus" >
            <a-select-option :value="0">
              未设置
            </a-select-option>
            <a-select-option :value="2">
              未推广
            </a-select-option>
            <a-select-option :value="5">
              推广0.5
            </a-select-option>
            <a-select-option :value="10">
              推广1
            </a-select-option>
            <a-select-option :value="15">
              推广1.5
            </a-select-option>
            <a-select-option :value="20">
              推广2
            </a-select-option>
            <a-select-option :value="25">
              推广2.5
            </a-select-option>
            <a-select-option :value="30">
              推广3
            </a-select-option>
            <a-select-option :value="35">
              推广3.5
            </a-select-option>
            <a-select-option :value="40">
              推广4
            </a-select-option>
            <a-select-option :value="45">
              推广4.5
            </a-select-option>
            <a-select-option :value="50">
              推广5
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='身份类型修改' :destroyOnClose='true' :maskClosable='true' width="30%"
             @cancel='()=>this.deliver11.visible=false' @ok="updateUserType"
             :visible='deliver11.visible' :confirmLoading='deliver11.loading'>
      <a-form-model ref='form11' :model='deliver11.form' :rules='deliver11.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>

        <a-form-model-item  label="身份类型" prop="fillStatus">
          <a-select v-model="deliver11.form.level" >
            <a-select-option value="normal">
              用户
            </a-select-option>
            <a-select-option value="vip">
              会员
            </a-select-option>
<!--            <a-select-option value="center">-->
<!--              备选服务-->
<!--            </a-select-option>-->
<!--            <a-select-option value="president">-->
<!--              备选运营-->
<!--            </a-select-option>-->
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='会员名额修改' :destroyOnClose='true' :maskClosable='true' width="30%"
             @cancel='()=>this.deliver12.visible=false' @ok="updateValueMember"
             :visible='deliver12.visible' :confirmLoading='deliver12.loading'>
      <a-form-model ref='form12' :model='deliver12.form' :rules='deliver12.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>

        <a-form-model-item  label="修改" prop="status">
          <a-select v-model="deliver12.form.status"  @change="updateValue3(deliver12.form)">
            <a-select-option :value="1">
              增加
            </a-select-option>
            <a-select-option :value="2">
              减少
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label='修改值' prop='value'>
          <a-input v-model='deliver12.form.value'  @change="updateValue3(deliver12.form)"/>
        </a-form-model-item>
        <a-form-model-item label='备注内容' prop='remark'>
          <a-input v-model='deliver12.form.remark' />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='会员名额明细' :destroyOnClose='true' :maskClosable='true' width="70%"
             @cancel='()=>this.deliver13.visible=false' :footer="null"
             :visible='deliver13.visible' :confirmLoading='deliver13.loading'>
      <a-form-model ref='form13' :model='deliver13.form'  layout='inline'>
        <a-form-model-item prop='business'>
          <a-select v-model="deliver13.form.business" placeholder="变动类型" style="width: 250px">
            <a-select-option :value="undefined">
              变动类型
            </a-select-option>
            <a-select-option :value="2">
              使用
            </a-select-option>
            <a-select-option :value="1">
              获得
            </a-select-option>
            <a-select-option :value="3">
              修改
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop='start'>
          <a-range-picker
              v-model="dateArr13"
              :format="[formatType, formatType]"
              :show-time="{
              hideDisabledOptions: true
          }"
              :disabled-date="disabledDate13"
              @change="dateChange13"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type='primary' @click='handleSubmit13'>
            查询
          </a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click='handleReset13'>
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <s-table id="table13" ref='table13' rowKey='id' size='default' :columns='columns13' :pageSize='30' :scroll='{ x: 1500}' :data='loadData13'>
      </s-table>
    </a-modal>

    <a-modal title='权益修改' :destroyOnClose='true' :maskClosable='true' width="30%"
             @cancel='()=>this.deliver14.visible=false' @ok="handleSubmit14"
             :visible='deliver14.visible' :confirmLoading='deliver14.loading'>
      <a-form-model ref='form14' :model='deliver14.form' :rules='deliver14.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>

        <a-form-model-item label='有效期' prop='end'>
          <a-date-picker format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"   v-model='deliver14.form.end' style="width: 100%" placeholder="选择日期"/>
        </a-form-model-item>
        <a-form-model-item label='剩余额度' prop='_partnerBalance'>
          <a-input v-model='deliver14.form._partnerBalance' disabled/>
        </a-form-model-item>


      <a-form-model-item  label="修改" prop="status">
        <a-select v-model="deliver14.form.status"  @change="updateValuePartnerValue(deliver14.form)">
          <a-select-option :value="1">
            增加
          </a-select-option>
          <a-select-option :value="2">
            减少
          </a-select-option>
        </a-select>
      </a-form-model-item>


      <a-form-model-item label='修改值' prop='partnerBalance'>
        <a-input v-model='deliver14.form.partnerBalance'  @change="updateValuePartnerValue(deliver14.form)"/>
      </a-form-model-item>

      </a-form-model>
    </a-modal>

    <a-modal title='权益额度明细' :destroyOnClose='true' :maskClosable='true' width="70%"
             @cancel='()=>this.deliver17.visible=false' :footer="null"
             :visible='deliver17.visible' :confirmLoading='deliver17.loading'>
      <a-form-model ref='form17' :model='deliver17.form'  layout='inline'>
        <a-form-model-item prop='business'>
          <a-select v-model="deliver17.form.business" placeholder="变动类型" style="width: 250px">
            <a-select-option :value="undefined">
              变动类型
            </a-select-option>
            <a-select-option :value="2">
              使用
            </a-select-option>
            <a-select-option :value="1">
              获得
            </a-select-option>
            <a-select-option :value="3">
              修改
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop='start'>
          <a-range-picker
              v-model="dateArr17"
              :format="[formatType, formatType]"
              :show-time="{
              hideDisabledOptions: true
          }"
              :disabled-date="disabledDate17"
              @change="dateChange17"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type='primary' @click='handleSubmit17'>
            查询
          </a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click='handleReset17'>
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <s-table id="table17" ref='table17' rowKey='id' size='default' :columns='columns17' :pageSize='30' :scroll='{ x: 1500}' :data='loadData17'>
      </s-table>
    </a-modal>

    <a-modal title='合伙人名额修改' :destroyOnClose='true' :maskClosable='true' width="30%"
             @cancel='()=>this.deliver15.visible=false' @ok="updateValuePartner"
             :visible='deliver15.visible' :confirmLoading='deliver15.loading'>
      <a-form-model ref='form15' :model='deliver15.form' :rules='deliver15.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>

        <a-form-model-item  label="修改" prop="status">
          <a-select v-model="deliver15.form.status"  @change="updateValue3(deliver15.form)">
            <a-select-option :value="1">
              增加
            </a-select-option>
            <a-select-option :value="2">
              减少
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label='修改值' prop='value'>
          <a-input v-model='deliver15.form.value'  @change="updateValue3(deliver15.form)"/>
        </a-form-model-item>
        <a-form-model-item label='备注内容' prop='remark'>
          <a-input v-model='deliver15.form.remark' />
        </a-form-model-item>
      </a-form-model>

    </a-modal>

    <a-modal title='合伙人名额明细' :destroyOnClose='true' :maskClosable='true' width="70%"
             @cancel='()=>this.deliver16.visible=false' :footer="null"
             :visible='deliver16.visible' :confirmLoading='deliver16.loading'>
      <a-form-model ref='form16' :model='deliver16.form'  layout='inline'>
        <a-form-model-item prop='business'>
          <a-select v-model="deliver16.form.business" placeholder="变动类型" style="width: 250px">
            <a-select-option :value="undefined">
              变动类型
            </a-select-option>
            <a-select-option :value="2">
              使用
            </a-select-option>
            <a-select-option :value="1">
              获得
            </a-select-option>
            <a-select-option :value="3">
              修改
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop='start'>
          <a-range-picker
              v-model="dateArr16"
              :format="[formatType, formatType]"
              :show-time="{
              hideDisabledOptions: true
          }"
              :disabled-date="disabledDate16"
              @change="dateChange16"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type='primary' @click='handleSubmit16'>
            查询
          </a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click='handleReset16'>
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <s-table id="table16" ref='table16' rowKey='id' size='default' :columns='columns16' :pageSize='30' :scroll='{ x: 1500}' :data='loadData16'>
      </s-table>
    </a-modal>

  </page-header-wrapper>
</template>

<script>
import {Ellipsis, STable} from '@/components'
import {
  queryActiveDetail,
  queryCumulateRecordDetail,
  queryExchangeRecordDetail,
  queryNumShareRecordDetail, queryPartnerRightDetail
} from '@/api/member-order'
import {
  getMemberCash,
  getOperatorDetail, putPause,
  updateCashBalance,
  updateExchange,
  updateFillStatus, updatePartnerRight,
  updateShareCount,
  updateValueBalance
} from '@/api/operator'

import moment from 'moment'
import {updateOrUpdateQuantity, updateQuantity} from "@/api/quantity";


const columns2 = [
  {
    width: 80,
    title: '变动类型',
    dataIndex: 'businessName',
    scopedSlots: { customRender: 'businessName' }
  },
  {
    width: 100,
    title: '变动内容',
    dataIndex: 'quantity',
    scopedSlots: { customRender: 'quantity' }
  },
  {
    width: 80,
    title: '变动后兑换积分',
    dataIndex: 'afterAmount',
    scopedSlots: { customRender: 'afterAmount' }
  },
  {
    width: 80,
    title: '发生订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 100,
    title: '变动时间',
    dataIndex: 'updateTime',
    scopedSlots: { customRender: 'updateTime' }
  },
  {
    width: 200,
    title: '备注',
    dataIndex: 'des',
    scopedSlots: { customRender: 'des' },
    sorter: true,
  }
]

const columns1 = [
  {
    width: 80,
    title: '变动类型',
    dataIndex: 'businessName',
    scopedSlots: { customRender: 'businessName' }
  },
  {
    width: 100,
    title: '变动内容',
    dataIndex: 'quantity',
    scopedSlots: { customRender: 'quantity' }
  },
  {
    width: 80,
    title: '变动后可用积分',
    dataIndex: 'afterBalance',
    scopedSlots: { customRender: 'afterBalance' }
  },
  {
    width: 80,
    title: '发生订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 100,
    title: '变动时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 200,
    title: '备注',
    dataIndex: 'des',
    scopedSlots: { customRender: 'des' },
    sorter: true,
  }
]

const columns6 = [
  {
    width: 80,
    title: '变动类型',
    dataIndex: 'businessName',
    scopedSlots: { customRender: 'businessName' }
  },
  {
    width: 100,
    title: '变动内容',
    dataIndex: 'value',
    scopedSlots: { customRender: 'value' }
  },
  {
    width: 80,
    title: '变动后份额数',
    dataIndex: 'afterValue',
    scopedSlots: { customRender: 'afterValue' }
  },
  {
    width: 80,
    title: '发生订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 100,
    title: '变动时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 200,
    title: '备注',
    dataIndex: 'remark',
    scopedSlots: { customRender: 'remark' },
    sorter: true,
  }
]

const columns7 = [
  {
    width: 80,
    title: '变动类型',
    dataIndex: 'businessName',
    scopedSlots: { customRender: 'businessName' }
  },
  {
    width: 100,
    title: '变动内容',
    dataIndex: 'value',
    scopedSlots: { customRender: 'value' }
  },
  {
    width: 80,
    title: '变动后累计值',
    dataIndex: 'afterValue',
    scopedSlots: { customRender: 'afterValue' }
  },
  {
    width: 80,
    title: '发生订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 100,
    title: '变动时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 200,
    title: '备注',
    dataIndex: 'remark',
    scopedSlots: { customRender: 'remark' },
    sorter: true,
  }
]

const columns13 = [
  {
    width: 80,
    title: '变动类型',
    dataIndex: 'businessName',
    scopedSlots: { customRender: 'businessName' }
  },
  {
    width: 100,
    title: '变动内容',
    dataIndex: 'businessContent',
    scopedSlots: { customRender: 'businessContent' }
  },
  {
    width: 80,
    title: '变动后名额',
    dataIndex: 'afterAmount',
    scopedSlots: { customRender: 'afterAmount' }
  },
  {
    width: 80,
    title: '发生订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 100,
    title: '变动时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 200,
    title: '备注',
    dataIndex: 'orderTypeName',
    scopedSlots: { customRender: 'orderTypeName' },
    sorter: true,
  }
]

const columns16 = [
  {
    width: 80,
    title: '变动类型',
    dataIndex: 'businessName',
    scopedSlots: { customRender: 'businessName' }
  },
  {
    width: 100,
    title: '变动内容',
    dataIndex: 'businessContent',
    scopedSlots: { customRender: 'businessContent' }
  },
  {
    width: 80,
    title: '变动后名额',
    dataIndex: 'afterAmount',
    scopedSlots: { customRender: 'afterAmount' }
  },
  {
    width: 80,
    title: '发生订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 100,
    title: '变动时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 200,
    title: '备注',
    dataIndex: 'orderTypeName',
    scopedSlots: { customRender: 'orderTypeName' },
    sorter: true,
  }
]

const columns17 = [
  {
    width: 80,
    title: '变动类型',
    dataIndex: 'businessName',
    scopedSlots: { customRender: 'businessName' }
  },
  {
    width: 100,
    title: '变动内容',
    dataIndex: 'quantity',
    scopedSlots: { customRender: 'quantity' }
  },
  {
    width: 80,
    title: '变动后额度',
    dataIndex: 'afterBalance',
    scopedSlots: { customRender: 'afterBalance' }
  },
  {
    width: 80,
    title: '发生订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 100,
    title: '变动时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 200,
    title: '备注',
    dataIndex: 'des',
    scopedSlots: { customRender: 'des' },
    sorter: true,
  }
]

const formatType = 'YYYY-MM-DD HH:mm';
const baseStart = moment().startOf('month').format(formatType);
// const baseEnd = moment().format(formatType);
const baseEnd = moment().startOf('day').format('YYYY-MM-DD')+ ' 23:59';
export default {
  name: 'ProposalEdit',

  data() {
    return {
      ext4:0,//列表数据汇总
      id:'',
      playImg: require('@/assets/play.jpg'),
      loading: false,
      profile: {},
      goodImagesList: [],
      badImagesList: [],
      usedImagesList: [],
      content: "",
      previewVisible: false,
      previewImage: "",
      formatType,
      dateArr: [baseStart, baseEnd],
      dateArr2: [baseStart, baseEnd],
      dateArr6: [baseStart, baseEnd],
      dateArr7: [baseStart, baseEnd],
      dateArr13: [baseStart, baseEnd],
      dateArr16: [baseStart, baseEnd],
      dateArr17: [baseStart, baseEnd],
      dashboard: {}, //统计数据
      // 表头1
      columns1,
      columns2,
      columns6,
      columns7,
      columns13,
      columns16,
      columns17,
      // 加载数据方法 必须为 Promise 对象
      loadData1: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        delete parameter.current;
        delete parameter.size;
        parameter.member = this.id;
        let params = Object.assign(parameter,this.queryParam, this.deliver1.form);
        return getMemberCash(params)
            .then(datum => {
              this.ext4 = datum.ext4;
              return datum
            })
      },
      loadData2: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        delete parameter.current;
        delete parameter.size;
        parameter.member = this.id;
        let params = Object.assign(parameter, this.queryParam,this.deliver2.form);
        debugger;
        return queryExchangeRecordDetail(params)
            .then(datum => {

              return datum
            })
      },
      loadData6: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        delete parameter.current;
        delete parameter.size;
        parameter.member = this.id;
        let params = Object.assign(parameter, this.queryParam,this.deliver6.form);
        // debugger;
        return queryNumShareRecordDetail(params)
            .then(datum => {
              // debugger;
              return datum
            })
      },
      loadData7: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        delete parameter.current;
        delete parameter.size;
        parameter.member = this.id;
        let params = Object.assign(parameter, this.queryParam,this.deliver7.form);
        // debugger;
        return queryCumulateRecordDetail(params)
            .then(datum => {

              return datum
            })
      },
      loadData13: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        delete parameter.current;
        delete parameter.size;
        parameter.id = this.id;
        // debugger
        let params = Object.assign(parameter, this.queryParam,this.deliver13.form);
        params.quotaType='6';//会员名额流水

        if(params.isDefault){
          delete params.start;
          delete params.end;
        }
        // debugger
        return queryActiveDetail(params)
            .then(datum => {
              return datum
            })
      },
      loadData16: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        delete parameter.current;
        delete parameter.size;
        parameter.id = this.id;
        // debugger
        let params = Object.assign(parameter, this.queryParam,this.deliver16.form);
        params.quotaType='7';//合伙人名额流水

        if(params.isDefault){
          delete params.start;
          delete params.end;
        }
        // debugger
        return queryActiveDetail(params).then(datum => {
              return datum
            })
      },
      loadData17: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        delete parameter.current;
        delete parameter.size;
        parameter.member = this.id;
        // debugger
        let params = Object.assign(parameter, this.queryParam,this.deliver16.form);

        if(params.isDefault){
          delete params.start;
          delete params.end;
        }
        // debugger
        return queryPartnerRightDetail(params).then(datum => {
          return datum
        })
      },
      deliver1: { visible: false,
        form: {
          start: baseStart,
          end: baseEnd,
        }, loading: false },
      deliver2: { visible: false, form: {
          start: baseStart,
          end: baseEnd,
        }, loading: false },
      deliver3: {
        visible: false,
        form: {
          status:1
        },
        rules: {
          status: [{ required: true, message: '请选择修改方式', trigger: 'change' }],
          value: [{ required: true, message: '请填写修改值', trigger: 'change' }],
          // remark: [{ required: true, message: '请填写备注内容', trigger: 'change' }],
        },
        loading: false
      },
      deliver4: {
        visible: false,
        form: {
          status:1
        },
        rules: {
          status: [{ required: true, message: '请选择修改方式', trigger: 'change' }],
          value: [{ required: true, message: '请填写修改值', trigger: 'change' }],
          // remark: [{ required: true, message: '请填写备注内容', trigger: 'change' }],
        },
        loading: false
      },
      deliver5: {
        visible: false,
        form: {
          status:1
        },
        rules: {
          status: [{ required: true, message: '请选择修改方式', trigger: 'change' }],
          value: [{ required: true, message: '请填写修改值', trigger: 'change' }],
          // remark: [{ required: true, message: '请填写备注内容', trigger: 'change' }],
        },
        loading: false
      },
      deliver6: { visible: false,
        form: {
          start: baseStart,
          end: baseEnd,
        },
        loading: false
      },
      deliver7: { visible: false,
        form: {
          start: baseStart,
          end: baseEnd,
        },
        loading: false
      },
      deliver8: {
        visible: false,
        form: {
          status:1
        },
        rules: {
          status: [{ required: true, message: '请选择修改方式', trigger: 'change' }],
          value: [{ required: true, message: '请填写修改值', trigger: 'change' }],
          // remark: [{ required: true, message: '请填写备注内容', trigger: 'change' }],
        },
        loading: false
      },
      deliver9: {
        visible: false,
        form: {
          status:1
        },
        rules: {
          status: [{ required: true, message: '请选择修改方式', trigger: 'change' }],
          value: [{ required: true, message: '请填写修改值', trigger: 'change' }],
          // remark: [{ required: true, message: '请填写备注内容', trigger: 'change' }],
        },
        loading: false
      },
      deliver10: {
        visible: false,
        form: {
          fillStatus:1
        },
        loading: false
      },
      deliver11: {
        visible: false,
        form: {
          level:'normal'
        },
        loading: false
      },
      deliver12: {
        visible: false,
        form: {
          status:1
        },
        rules: {
          status: [{ required: true, message: '请选择修改方式', trigger: 'change' }],
          value: [{ required: true, message: '请填写修改值', trigger: 'change' }],
          // remark: [{ required: true, message: '请填写备注内容', trigger: 'change' }],
        },
        loading: false
      },
      deliver13: { visible: false,
        form: {
          isDefault:true,
          start: baseStart,
          end: baseEnd,
        },
        loading: false
      },
      deliver14: { visible: false,
        form: {
          end :'',
        },
        rules: {
          end: [{ required: true, message: '填写权益有效期', trigger: 'change' }],
          status: [{ required: true, message: '请选择修改方式', trigger: 'change' }],
          partnerBalance: [{ required: true, message: '请填写修改值', trigger: 'change' }],
        },
        loading: false
      },
      deliver15: { visible: false,
        form: {
          status:1
        },
        rules: {
          status: [{ required: true, message: '请选择修改方式', trigger: 'change' }],
          value: [{ required: true, message: '请填写修改值', trigger: 'change' }],
          // remark: [{ required: true, message: '请填写备注内容', trigger: 'change' }],
        },
        loading: false
      },
      deliver16: { visible: false,
        form: {
          isDefault:true,
          start: baseStart,
          end: baseEnd,
        },
        loading: false
      },
      deliver17: { visible: false,
        form: {
          isDefault:true,
          start: baseStart,
          end: baseEnd,
        },
        loading: false
      },
    }
  },
  components: { STable, Ellipsis },
  created() {

    this.handleInitial()
  },
  activated() {

    this.handleInitial();

  },
  methods: {
    showTypeText6(type){
      let text = "";
      if(type ===1){
        text = "获得";
      }else  if(type ===2){
        text = "奖励扣除";
      }else  if(type ===3){
        text = "修改";
      }else  if(type ===4){
        text = "商户差值扣除";
      }
      return text;
    },
    showTypeText7(type){
      let text = "";
      if(type ===1){
        text = "获得";
      }else  if(type ===2){
        text = "奖励扣除";
      }else  if(type ===3){
        text = "修改";
      }else  if(type ===4){
        text = "使用";
      }
      return text;
    },
    showCaseText(record){
      // debugger;
      let text = "";
      if(record.businessName==='修改'||record.businessName==='受让'||record.businessName==='转让'){
        // debugger
        text = record.opUsername;
      }else{
        text = record.orderId;
      }
      // debugger;
      // text = text + record;
      return text;
    },
    getTypeNameStr(type,record) {
      if(record.shareholder=='1'){
        //股东判断
        return '股东'
      }
      if(type=='store'){
        return '门店'
      }else if(type=='center') {
        return '服务中心';
      }else if(type=='president') {
        return '运营总裁';
      }else if(type=='normal'){
        if(record.level=='vip'){
          return '会员';
        }
        // debugger;
        // if(record.level=='president'){
        //   return '备选运营';
        // } else if(record.level=='center'){
        //   return '备选服务';
        // }
        return '用户';
      }else{
        return '';
      }
    },
    getSpreaderTypeNameStr(type,record) {
      if(record.spreaderShareholder=='1'){
        //股东判断
        return '股东'
      }

      if(type=='store'){
        return '门店'
      }else if(type=='center') {
        return '服务中心';
      }else if(type=='president') {
        return '运营总裁';
      }else if(type=='normal'){
        // debugger;
        if(record.spreaderLevel=='president'){
          return '备选运营';
        } else if(record.spreaderLevel=='center'){
          return '备选服务';
        }
        return '用户';
      }else{
        return '';
      }
    },
    isMerhant(record) {
      // if(record.spreaderShareholder=='1'){
      //   //股东判断
      //   return true
      // }
      if(record.type=='store'){
        return true;
      }else if(record.type=='center') {
        return true;
      }else if(record.type=='president') {
        return true;
      }else if(record.type=='normal'){
        // debugger;
        if(record.level=='president'){
          return true;
        } else if(record.level=='center'){
          return true;
        }
        return false;
      }else{
        return false;
      }
    },
    handleSubmit1() {
      this.handleInitial();
      this.$refs.table1.refresh(true)
    },
    handleReset1() {
      this.dateArr = [baseStart, baseEnd];
      this.deliver1.form.start = baseStart;
      this.deliver1.form.end = baseEnd;
      this.$refs.form1.resetFields()
      this.$refs.table1.refresh(true)
    },
    handleSubmit2() {
      this.$refs.table2.refresh(true)
    },
    handleSubmit6() {
      this.$refs.table6.refresh(true)
    },
    handleSubmit7() {
      this.$refs.table7.refresh(true)
    },
    handleSubmit13() {
      this.deliver13.form.isDefault = false;
      this.$refs.table13.refresh(true)
    },
    handleSubmit14() {
      this.$refs.form14.validate(result => {
        if (result) {
          this.deliver14.form.loading = true;
          let params = Object.assign({id:this.id},this.deliver14.form);
          // debugger
          // params.memberEnd = params.memberEnd+" 23:59:59"
          updatePartnerRight(params).then(result => {
            this.$message.success('操作成功')
            this.deliver14.form.loading = false;
            this.deliver14.visible=false;
            this.handleInitial();
          })
        }
      });
    },
    handleSubmit15() {
      this.$refs.form15.validate(result => {
        if (result) {
          this.deliver15.form.loading = true;
          let params = Object.assign({id:this.id},this.deliver15.form);
          // params.partnerEnd = params.partnerEnd+" 23:59:59"
          params.partner = 1;

          updatePartnerRight(params).then(result => {
            this.$message.success('操作成功')
            this.deliver15.form.loading = false;
            this.deliver15.visible=false;
            this.handleInitial();
          })
        }
      });
    },
    handleSubmit16() {
      this.deliver16.form.isDefault = false;
      this.$refs.table16.refresh(true)
    },
    handleSubmit17() {
      this.deliver17.form.isDefault = false;
      this.$refs.table17.refresh(true)
    },
    handleReset2() {
      this.dateArr2 = [baseStart, baseEnd];
      this.deliver2.form.start = baseStart;
      this.deliver2.form.end = baseEnd;
      this.$refs.form2.resetFields()
      this.$refs.table2.refresh(true)
    },
    handleReset6() {
      this.dateArr6 = [baseStart, baseEnd];
      this.deliver6.form.start = baseStart;
      this.deliver6.form.end = baseEnd;
      this.$refs.form6.resetFields()
      this.$refs.table6.refresh(true)
    },
    handleReset7() {
      this.dateArr7 = [baseStart, baseEnd];
      this.deliver7.form.start = baseStart;
      this.deliver7.form.end = baseEnd;
      this.$refs.form7.resetFields()
      this.$refs.table7.refresh(true)
    },
    handleReset13() {
      this.dateArr13 = [baseStart, baseEnd];
      this.deliver13.form.start = baseStart;
      this.deliver13.form.end = baseEnd;
      this.deliver13.form.isDefault = true;
      this.$refs.form13.resetFields()
      this.$refs.table13.refresh(true)
    },
    handleReset16() {
      this.dateArr16 = [baseStart, baseEnd];
      this.deliver16.form.start = baseStart;
      this.deliver16.form.end = baseEnd;
      this.deliver16.form.isDefault = true;
      this.$refs.form16.resetFields()
      this.$refs.table16.refresh(true)
    },
    handleReset17() {
      this.dateArr17 = [baseStart, baseEnd];
      this.deliver17.form.start = baseStart;
      this.deliver17.form.end = baseEnd;
      this.deliver17.form.isDefault = true;
      this.$refs.form17.resetFields()
      this.$refs.table17.refresh(true)
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.deliver1.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.formatType) : "";
      this.deliver1.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.formatType) : "";
    },
    disabledDate2(current) {
      return current && current > moment().endOf('day');
    },
    disabledDate13(current) {
      return current && current > moment().endOf('day');
    },
    disabledDate16(current) {
      return current && current > moment().endOf('day');
    },
    disabledDate17(current) {
      return current && current > moment().endOf('day');
    },
    dateChange2() {
      this.deliver2.form.start =  this.dateArr2[0] ? moment(this.dateArr2[0]).format(this.formatType) : "";
      this.deliver2.form.end =  this.dateArr2[1] ? moment(this.dateArr2[1]).format(this.formatType) : "";
    },
    dateChange6() {
      this.deliver6.form.start =  this.dateArr6[0] ? moment(this.dateArr6[0]).format(this.formatType) : "";
      this.deliver6.form.end =  this.dateArr6[1] ? moment(this.dateArr6[1]).format(this.formatType) : "";
    },
    dateChange7() {
      this.deliver7.form.start =  this.dateArr7[0] ? moment(this.dateArr7[0]).format(this.formatType) : "";
      this.deliver7.form.end =  this.dateArr7[1] ? moment(this.dateArr7[1]).format(this.formatType) : "";
    },
    dateChange13() {
      this.deliver13.form.start =  this.dateArr13[0] ? moment(this.dateArr13[0]).format(this.formatType) : "";
      this.deliver13.form.end =  this.dateArr13[1] ? moment(this.dateArr13[1]).format(this.formatType) : "";
    },
    dateChange16() {
      this.deliver16.form.start =  this.dateArr16[0] ? moment(this.dateArr16[0]).format(this.formatType) : "";
      this.deliver16.form.end =  this.dateArr16[1] ? moment(this.dateArr16[1]).format(this.formatType) : "";
    },
    dateChange17() {
      this.deliver17.form.start =  this.dateArr17[0] ? moment(this.dateArr17[0]).format(this.formatType) : "";
      this.deliver17.form.end =  this.dateArr17[1] ? moment(this.dateArr17[1]).format(this.formatType) : "";
    },
    handleInitial1() {
      this.deliver1.loading = false
      this.deliver1.visible = true
    },
    handleInitial2() {
      this.deliver2.loading = false
      this.deliver2.visible = true
    },
    handleInitial6() {
      this.deliver6.loading = false
      this.deliver6.visible = true
    },
    handleInitial7() {
      this.deliver7.loading = false
      this.deliver7.visible = true
    },
    handleInitialTotal(deliver){
      deliver.loading = false;
      deliver.visible = true
    },
    handleInitial14(){
      let partnerBalance = this.dashboard.partnerBalance;
      let partnerEnd = this.dashboard.partner_end;
      this.deliver14.form._partnerBalance = partnerBalance;

      // this.deliver14.form.status =1;
      this.deliver14.form.end = partnerEnd;
      // this.deliver14.form.partnerBalance = 0;

      this.deliver14.loading = false
      this.deliver14.visible = true
    },
    handleInitial15(){
      this.deliver15.loading = false
      this.deliver15.visible = true
    },
    handleInitial(){
      this.id = this.$route.query.id;

      getOperatorDetail(Object.assign({id:this.id})).then(result => {
        // result.isType = this.isMerhant(result);
        let temp_member_end = result.memberEnd;
        let temp_partner_end = result.partnerEnd;

        let member_end = moment(temp_member_end).format('YYYY-MM-DD');
        let partner_end = moment(temp_partner_end).format('YYYY-MM-DD');

        result.member_end = member_end;
        result.partner_end = partner_end;

        this.dashboard = result;
        this.loading = false;
      })
    },
    handelInitialCumulativeValueBalance(){
      this.deliver3.form={status: 1};
      this.deliver3.loading = false
      this.deliver3.visible = true
    },
    handelInitialShareValue(item){
      let goalId = "";
      // debugger;
      if(item){
        goalId = item.thisGoalId;
      }
      this.deliver4.form={status: 1,goalId:goalId};
      this.deliver4.loading = false
      this.deliver4.visible = true
    },
    handelInitialCashBalance(){
      this.deliver8.loading = false
      this.deliver8.visible = true
    },
    handelInitialDuihuan(){
      this.deliver9.loading = false
      this.deliver9.visible = true
    },
    handelInitialFillStatus(){
      let fillStatus = this.dashboard.fillStatus;
      this.deliver10.form.fillStatus = fillStatus;

      this.deliver10.loading = false
      this.deliver10.visible = true
    },
    handelInitialMember(){
      let fillStatus = this.dashboard.fillStatus;
      this.deliver10.form.fillStatus = fillStatus;

      this.deliver10.loading = false
      this.deliver10.visible = true
    },

    handelUserLevel(){
      let level = this.dashboard.level;

      this.deliver11.form = {
        level: level,
      }
      this.deliver11.loading = false
      this.deliver11.visible = true
    },
    updateCumulativeValueBalance(){
      this.$refs.form3.validate(result => {
        if (result) {
          this.deliver3.loading = true;
          let params = Object.assign({id:this.id,value:this.deliver3.form.value,remark:this.deliver3.form.remark});

          updateValueBalance(params).then(result => {
            this.$message.success('操作成功')
            this.deliver3.form.loading = false;
            this.deliver3.visible=false;
            this.handleInitial();
          })
        }
      });
    },
    updateShareValue(){
      this.$refs.form4.validate(result => {
        if (result) {
          this.deliver4.loading = true;
          let params = Object.assign({id:this.id,value:this.deliver4.form.value,goalId:this.deliver4.form.goalId,remark:this.deliver4.form.remark});
          updateShareCount(params).then(result => {
            this.$message.success('操作成功')
            this.deliver4.form.loading = false;
            this.deliver4.visible=false;
            this.handleInitial();
          }).catch(e=>{
            this.deliver4.loading = false;
            this.$message.error(e.data.errorMsg);
          })
        }
      });
    },
    //份额数修改
    updateShare(){
      this.$refs.form5.validate(result => {
        if (result) {
          this.deliver5.form.loading = true;
          let params = Object.assign({id:this.id},this.deliver5.form);
          debugger;
          updateShareCount(params).then(result => {
            this.$message.success('操作成功')
            this.deliver5.form.loading = false;
            this.deliver5.visible=false;
            this.handleInitial();
          })
        }
      });
    },
    updateValue4(form){
      let value = form.value;
      let e = /^(\-|\+)?\d+(\.\d+)?$/.test(value);
      // form.remark = e;
      if(value && e) {
        value = Math.abs(value);
        if (form.status == '1') {
          //增加
          form.value = value;
        } else {
          //减少
          form.value = -value;
        }
      }else if(!value){
        console.log("空字符串")

      }else{
        this.$message.error('只能填写整数或小数！')
        form.value = "";
      }
    },
    updateValue8(form){
      let value = form.value;
      let e = /^(\-|\+)?\d+?$/.test(value);
      // form.remark = e;
      if(value && e) {
        value = Math.abs(value);
        if (form.status == '1') {
          //增加
          form.value = value;
        } else {
          //减少
          form.value = -value;
        }
      }else if(!value){
        console.log("空字符串")

      }else{
        this.$message.error('只能填写整数！')
        form.value = "";
      }
    },
    updateValue3(form){
      let value = form.value;
      let e = /^(\-|\+)?\d+$/.test(value);
      // form.remark = e;
      if(value && e) {
        value = Math.abs(value);
        if (form.status == '1') {
          //增加
          form.value = value;
        } else {
          //减少
          form.value = -value;
        }
      }else if(!value){
        console.log("空字符串")

      }else{
        this.$message.error('只能填写整数！')
        form.value = "";
      }
    },
    updateValueMemberValue(form){
      let value = form.memberBalance;
      let e = /^(\-|\+)?\d+$/.test(value);

      debugger
      if(value && e) {
        value = Math.abs(value);
        if (form.status == '1') {
          //增加
          form.memberBalance = value;
        } else {
          //减少
          form.memberBalance = -value;
        }
      }else if(!value){
        console.log("空字符串")

      }else{
        this.$message.error('只能填写整数！')
        form.memberBalance = "";
      }
    },
    updateValuePartnerValue(form){
      let value = form.partnerBalance;
      let e = /^(\-|\+)?\d+$/.test(value);
      // form.remark = e;
      if(value && e) {
        value = Math.abs(value);
        if (form.status == '1') {
          //增加
          form.partnerBalance = value;
        } else {
          //减少
          form.partnerBalance = -value;
        }
      }else if(!value){
        console.log("空字符串")

      }else{
        this.$message.error('只能填写整数！')
        form.partnerBalance = "";
      }
    },
    //修改可用积分
    updateCashBalance(){
      this.$refs.form8.validate(result => {
        if (result) {
          this.deliver8.form.loading = true;
          let params = Object.assign({id:this.id},this.deliver8.form);
          // debugger;
          updateCashBalance(params).then(result => {
            this.$message.success('操作成功')
            this.deliver8.form.loading = false;
            this.deliver8.visible=false;
            this.handleInitial();
          })
        }
      });
    },
    //修改兑换积分
    updateExchange(){
      this.$refs.form9.validate(result => {
        if (result) {
          this.deliver9.form.loading = true;
          let params = Object.assign({id:this.id},this.deliver9.form);
          // debugger;
          updateExchange(params).then(result => {
            this.$message.success('操作成功')
            this.deliver9.form.loading = false;
            this.deliver9.visible=false;
            this.handleInitial();
          })
        }
      });
    },
    //修改份额类型
    updateFillStatus(){
      this.deliver10.form.loading = true;
      if(this.deliver10.form.fillStatus == '0'){
        this.$message.error("不能选择未设置！")
        return;
      }
      let params = Object.assign({id:this.id},this.deliver10.form);
      debugger;
      updateFillStatus(params).then(result => {
        this.$message.success('操作成功')
        this.deliver10.form.loading = false;
        this.deliver10.visible=false;
        this.handleInitial();
      })
    },
    //修改身份类型
    updateUserType(){
      this.deliver11.form.loading = true;
      let params = Object.assign({id:this.id,type:'normal',level:this.deliver11.form.level});

      putPause(params).then(result => {
        this.$message.success('操作成功')
        this.deliver11.form.loading = false;
        this.deliver11.visible=false;
        this.handleInitial();
      })
    },
    //打开会员名额修改
    handelMemberInitial(){
      this.deliver12.loading = false
      this.deliver12.visible = true
    },
    //会员名额修改方法
    updateValueMember(){
      this.$refs.form12.validate(result => {
        if (result) {
          this.deliver12.loading = true;
          let params = Object.assign({id:this.id,amount:this.deliver12.form.value,remark:this.deliver12.form.remark,quotaType:'6'});
          updateQuantity(params).then(result => {
            this.$message.success('操作成功')
            this.deliver12.form.loading = false;
            this.deliver12.visible=false;
            this.handleInitial();
          })
        }
      });
    },
    //合伙人名额修改方法
    updateValuePartner(){
      this.$refs.form15.validate(result => {
        if (result) {
          this.deliver15.loading = true;
          let params = Object.assign({id:this.id,amount:this.deliver15.form.value,remark:this.deliver15.form.remark,quotaType:'7'});
          debugger
          updateQuantity(params).then(result => {
            this.$message.success('操作成功')
            this.deliver15.form.loading = false;
            this.deliver15.visible=false;
            this.handleInitial();
          })
        }
      });
    },
  }
}
</script>
<style lang="less" scoped>
.image_list {
  width: 150px;
  height: 150px;
  margin: 0 20px 20px 0;
  object-fit: cover;
  position: relative;
  .image_detail {
    height: 100%;
    width: 100%;
  }
  .play_image {
    position: absolute;
    width: 50px;
    height: 50px;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
  }
}

.full-modal {
  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }
  .ant-modal-body {
    flex: 1;
  }
}
</style>
